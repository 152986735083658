import health from "../../assets/icons/requirement/HealthInsurance.svg";
import life from "../../assets/icons/requirement/LifeInsurance.svg";
import general from "../../assets/icons/requirement/Generalnsurance.svg";
import React, { ReactNode } from "react";
import LifeIcon from "../../assets/icons/newDashboard/Life.svg";
import HealthIcon from "../../assets/icons/newDashboard/Health.svg";
import GeneralIcon from "../../assets/icons/newDashboard/General.svg";

export const requirementContants = [
  {
    name: "Health Insurance",
    img: health,
    requirementDocuments: [
      {
        policyName: "Claim Rejected",
        documentNames: ["Policy Document*", "Discharge Summary*", "Claim Rejection Letter ( For claim rejection cases)*", "Final Bills", "Indoor hospital documents (In case of misrepresentation/fraudulent claim)", "Previous Policy copies", "Doctor Certificate", "Any communications with the company", "Video/Audio/Written Statement- Complete case details", "Any other document you would like to provide"]
      },
      {
        policyName: "Claim Delayed",
        documentNames: ["Policy Document*", "Discharge Summary*", "Previous Policy copies", "Any communications with the company", "Video/Audio/ Written Statement- Complete case details", "Any other document you would like to provide"]
      },
      {
        policyName: "Claim Short-settled",
        documentNames: ["Policy Document*", "Discharge Summary*", "Claim Settlement Letter*", "Final Bills and receipts*", "Previous Policy copies", "Any communication with the company", "Video/Audio/ Written Statement- Complete case details", "Any other document you would like to provide"]
      }
    ]
  },
  {
    name: "Life Insurance",
    img: life,
    requirementDocuments: [
      {
        policyName: "Mis-selling/ Fraud",
        documentNames: ["Policy document", "WhatsApp chats/Screenshots", "Any mail communication with the company", "Proof of Recordings", "Recording/Statement- client’s case details"]
      },
      {
        policyName: " Death Claim Delayed",
        documentNames: ["Policy Document", "Death Certificate", "FIR", "Post Mortem Report", "Claim Form", "Doctor's Report", "Recording/Statement- client’s case details", "Any mail communication with the company"]
      },
      {
        policyName: " Death Claim Rejected",
        documentNames: ["Policy Document", "Death Certificate", "FIR", "Post Mortem Report", "Rejection Letter", "Claim Form", "Doctor's Report", "Recording/Statement- client’s case details", "Any mail communication with the company"]
      }
    ]
  },
  {
    name: "General Insurance",
    img: general,
    requirementDocuments: [
      {
        policyName: " General Insurance (Motor Insurance) ",
        documentNames: ["Policy copy", "Rejection Letter/ Settlement letter (depending on complaint type)", "Surveyor Report", "Mail Communication with Insurance Company/ombudsman", "Claim form", "Accident photographs (If Available)", "Recording/Statement- client’s case details"]
      },
      {
        policyName: "General Insurance (Fire Insurance/Marine Insurance) ",
        documentNames: ["Policy copy", "Rejection Letter/ Settlement letter (depending on complaint type)", "Surveyor Report", "Mail Communication with Insurance Company/ombudsman", "Claim form", "Accident photographs (If Available)", "Loss details", "Recording/Statement- client’s case details"]
      }
    ]
  }
];

export const stateList = [
  {
    name: "All India",
    states: ["Pan India"]
  },
  {
    name: "States and Union Territories",
    states: ["Andaman & Nicobar", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman & Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu & Kashmir", "Jharkhand", "Karnataka", "Kerala", "Lakshadweep", "Ladakh", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh", "West Bengal"]
  }
];

export const allStates = ["Andaman & Nicobar", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman & Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu & Kashmir", "Jharkhand", "Karnataka", "Kerala", "Lakshadweep", "Ladakh", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh", "West Bengal"];

export const genderSelect = [
  { label: "None", value: "None" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" }
];

export const todayDateTimestamp = new Date().getTime();

export const daysTimestamp8 = 691200000;

export const reportDateTypeComplaint = [
  { label: "Accepted Date", value: "accepted" },
  { label: "Created Date", value: "created" },
  { label: "Resolved Date", value: "resolved" },
  { label: "Contract Signed Date", value: "contract" }
];

export const reportDateTypeLead = [
  { label: "Accepted Date", value: "accepted" },
  { label: "Created Date", value: "created" }
];

export const contractSignedArr = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" }
];

export const emojis = ["🙂", "😃", "😜", "😅", "😁", "😯", "😍", "😦", "😂", "😄", "😐", "😟", "😣", "🥺", "🤗", "👍", "💟", "🙏"];

export const faqContent: { question: string; answer: string | ReactNode }[] = [
  {
    question: "Why should I join Insurance Samadhan? ",
    answer: "As an advisor, you may face difficulty initiating conversations with prospects or clients due to unresolved complaints with insurance companies. With Insurance Samadhan, you can now register the case, and we'll assist in resolving it. Once resolved, your client's trust in you will increase, leading to new opportunities through referrals and sales."
  },
  {
    question: "How do I log into my dashboard?",
    answer: (
      <span>
        Logging into your portal is simple. Click the link below and enter your email and password:{" "}
        <a href="https://partnersadmin.insurancesamadhan.com/auth/login" rel="noreferrer" target="_blank" className="text-blue-600">
          https://partnersadmin.insurancesamadhan.com/auth/login
        </a>{" "}
        . If you forget your password, click 'Recover your Password' to receive a temporary password via email for logging in. After logging in, you can change your password using the 'Change password' option.
      </span>
    )
  },
  {
    question: "What types of complaints can I register with Insurance Samadhan?",
    answer: "Insurance Samadhan assists with complaints across all verticals: Life Insurance, Health Insurance, and General Insurance, including issues like Mis-selling, Fraud, Claim rejection, Claim delay, and Claim short settlement. We also handle cases concerning Marine Insurance, Commercial Insurance, Property Claims, Workmen Compensation claims, and more."
  },
  {
    question: "How does Insurance Samadhan resolve complaints?",
    answer: "We've streamlined the process from registration to resolution, utilizing avenues such as insurers, IRDAI, Ombudsman, and Consumer Forums for solutions. Our professionally trained team at InSa comprehends and addresses insurance complaints. With our Co-Founders boasting over 100 years of cumulative insurance industry experience and more than 25 years of legal expertise, we ensure effective resolution. Furthermore, we educate customers about their mistakes, empowering them to make informed decisions regarding insurance in the future."
  },
  {
    question: "What is the fee for joining Insurance Samadhan?",
    answer: "There are no fees for Advisors to join InSa. It's a win-win-win situation for you, customers, and us. It's also a win for insurance companies because they gain satisfied customers."
  },
  {
    question: "What is the fee paid by the customer?",
    answer: "As per our standard process, we charge a Rs.500 registration fee per customer. However, there are no registration fees for customers registering via Advisors. After resolving and crediting money to the client’s account, we charge a 15% Success Fee (plus applicable GST) on the recovered amount."
  },
  {
    question: "How to join Insurance Samadhan?",
    answer: (
      <span>
        It is easy to join us. You can use our mobile app '<span className="font-extrabold">Polifyx Partner</span>' or visit this link:{" "}
        <a href="https://partnersadmin.insurancesamadhan.com/auth/login" rel="noreferrer" target="_blank" className="text-blue-600">
          https://partnersadmin.insurancesamadhan.com/auth/login
        </a>{" "}
        to sign up as a partner. Alternatively, you can contact us at <span className="font-extrabold">9810524526</span>.
      </span>
    )
  },
  {
    question: "As a Life/General/Health Insurance advisor, am I eligible to join Insurance Samadhan?",
    answer: "Yes, you can. We are service providers, and anyone can be part of Insurance Samadhan."
  },
  {
    question: "Would I have any targets?",
    answer: "No, there are no targets. Our objective is to help your prospects and customers resolve their issues. The more prospects you help, the happier clients you'll have."
  },
  {
    question: "Does Insurance Samadhan sell insurance or any other financial product?",
    answer: "No, Insurance Samadhan is not involved in selling any financial products. We only resolve Insurance complaints."
  },
  {
    question: "Why should a customer pay Insurance Samadhan when he/she has already been cheated out of money?",
    answer: "We assist in recovering lost funds due to fraud or mis-selling. Our experts represent the customer legally, increasing the chances of recovery. We charge a success fee only upon money recovery."
  },
  {
    question: "My client's claim is my responsibility, so why should I join hands with Insurance Samadhan?",
    answer: "Your clients may have policies from other advisors or bankers, which you may not be able to handle directly. Additionally, during prospecting, you may find that many prospects have been mis-sold or had their claims declined in the past. With InSa, you can now assist them in recovering their money, demonstrating your commitment to service."
  },
  {
    question: "My insurance company pays me, so why would I go against them?",
    answer: "You don't need to submit a claim to your insurance company unless you are convinced that the case has merit to be represented after repudiation."
  },
  {
    question: "You already have a website; people can reach you directly. Why do you need me?",
    answer: "If visitors come directly to our website, they pay a Rs.500 registration fee. However, if they come through you, it's waived. Once they recover their lost money, you can sell them the appropriate product and earn through referrals."
  },
  {
    question: "If I bring a case to you, would you inform the insurance company that I'm referring cases to you? They might cancel my membership.",
    answer: "No, it's confidential. You're bringing your customer's case to us for professional representation. We believe insurance companies aim to settle genuine claims but lack necessary information from customers."
  },
  {
    question: "I am not from Delhi, how can I work with you? How will I see my cases progress regularly?",
    answer: "We are a tech platform with insurance advisors Pan India. You can register your case from anywhere and track its progress by accessing your dashboard 24/7."
  },
  {
    question: "Do you conduct any programs or webinars for advisors to enhance their prospecting?",
    answer: "Yes, we regularly conduct learning sessions led by industry experts and share knowledge snippets to assist you in your client interactions."
  },
  {
    question: "What support do you provide to advisors?",
    answer: (
      <span>
        Apart from 24x7 access to the Insurance Samadhan platform, we provide personalized promotional materials and links to testimonials that you can use to represent yourself and educate customers. Our tech support is active round the clock, so you can reach out to us anytime for any issues, concerns, or suggestions. Additionally, we offer co-branding services to our advisors. For more information, please call <span className="font-extrabold">9810524526.</span>
      </span>
    )
  },
  {
    question: "Can I refer to other people who are active in the Insurance sector but are not advisors?",
    answer: "Yes. However, we prefer only insurance advisors, mutual fund advisors, wealth managers, CAs, and lawyers to join InSa, as they would benefit the most."
  },
  {
    question: "What is Polifyx Partner?",
    answer: "Polifyx Partner is a mobile application designed for our partners, advisors, and associates, offering a variety of solutions for managing your customers and subordinates. These include uploading insurance policies, managing portfolios, submitting insurance claim complaints, tracking real-time updates, accessing payout details, and analyzing customized reports."
  },
  {
    question: " How to add a case/lead from Polifyx Partner?",
    answer: (
      <span>
        Watch the video and add a case/lead from Polifyx Partner in a few easy steps{" "}
        <a href="https://youtu.be/hojWpbuHaF4?si=nFEYBvFXYl6k1NAk" rel="noreferrer" target="_blank" className="text-blue-600">
          https://youtu.be/hojWpbuHaF4?si=nFEYBvFXYl6k1NAk
        </a>
      </span>
    )
  }
];

export const docDynamicField = (policyId: string) => {
  const docFields: any = {
    "General Insurance": [
      { name: "policyBook", label: "Policy Book", fileName: "PolicyBook" },
      { name: "rejectionLetter", label: "Rejection Letter", fileName: "RejectionLetter" },
      policyId !== "ac06fa4b-c645-4ba2-8ea8-8a6816e02f35" && policyId !== "2a9ac998-6a27-4ddf-8dbd-3044d149fe57" && { name: "claimBill", label: "Claim Bill", fileName: "ClaimBill" },
      policyId !== "ac06fa4b-c645-4ba2-8ea8-8a6816e02f35" && policyId !== "2a9ac998-6a27-4ddf-8dbd-3044d149fe57" && policyId !== "e65ffb3d-a306-4653-b78b-da19571ce39a" && policyId !== "ce667069-fd18-423f-9030-bcd084827c93" && { name: "surveyorReport", label: "Surveyor Report", fileName: "SurveyorReport" },
      policyId !== "ac06fa4b-c645-4ba2-8ea8-8a6816e02f35" && policyId !== "2a9ac998-6a27-4ddf-8dbd-3044d149fe57" && policyId !== "e65ffb3d-a306-4653-b78b-da19571ce39a" && policyId !== "ce667069-fd18-423f-9030-bcd084827c93" && { name: "settlementLetter", label: "Settlement Letter_", fileName: "SettlementLetter" },
      policyId !== "ac06fa4b-c645-4ba2-8ea8-8a6816e02f35" && policyId !== "2a9ac998-6a27-4ddf-8dbd-3044d149fe57" && policyId !== "e65ffb3d-a306-4653-b78b-da19571ce39a" && policyId !== "ce667069-fd18-423f-9030-bcd084827c93" && { name: "communicationCompany", label: "Communication Company", fileName: "CommunicationCompany" },
      { name: "callRecording", label: "Call Recording", fileName: "CallRecording" },
      (policyId == "ac06fa4b-c645-4ba2-8ea8-8a6816e02f35" || policyId == "2a9ac998-6a27-4ddf-8dbd-3044d149fe57" || policyId == "e65ffb3d-a306-4653-b78b-da19571ce39a" || policyId == "ce667069-fd18-423f-9030-bcd084827c93") && { name: "deathCertificateGi", label: "Death Certificate", fileName: "DeathCertificateGi" },
      (policyId == "e65ffb3d-a306-4653-b78b-da19571ce39a" || policyId == "e667069-fd18-423f-9030-bcd084827c93") && { name: "dischargeSummGi", label: "Discharge Summary ", fileName: "DischargeSummGi" },
      (policyId == "e65ffb3d-a306-4653-b78b-da19571ce39a" || policyId == "ce667069-fd18-423f-9030-bcd084827c93" || policyId == "03a8e513-55be-4577-9635-08225b4aa343" || policyId == "175adc16-a774-4749-83e4-1bac95fc098c" || policyId == "ac06fa4b-c645-4ba2-8ea8-8a6816e02f35") && { name: "FIRGi", label: "FIR ", fileName: "FIRGi" },
      (policyId == "ac06fa4b-c645-4ba2-8ea8-8a6816e02f35" || policyId == "2a9ac998-6a27-4ddf-8dbd-3044d149fe57") && { name: "postMortemReportGi", label: "PostMortem Report ", fileName: "PostMortemReportGi" },
      (policyId == "ac06fa4b-c645-4ba2-8ea8-8a6816e02f35" || policyId == "2a9ac998-6a27-4ddf-8dbd-3044d149fe57") && { name: "drReportGi", label: "Dr Report", fileName: "DrReportGi" }
    ],

    "Life Insurance": [
      policyId !== "9ac53f4c-c946-4034-9195-34cbaa97dd9e" && policyId !== "933645b0-6306-41a2-a0e0-14a07e6e7023" && policyId !== "3767c281-97f1-4fa5-ac66-41025ef4bb04" && policyId !== "03a8e513-55be-4577-9635-08225b4aa343" && { name: "policySchedule", label: "Policy Schedule", fileName: "PolicyDoc" },
      (policyId === "9ac53f4c-c946-4034-9195-34cbaa97dd9e" || policyId === "933645b0-6306-41a2-a0e0-14a07e6e7023" || policyId === "3767c281-97f1-4fa5-ac66-41025ef4bb04" || policyId === "03a8e513-55be-4577-9635-08225b4aa343") && { name: "proofOfRecording", label: "Proof of Recording", fileName: "ProofOfRecord" },
      (policyId === "9ac53f4c-c946-4034-9195-34cbaa97dd9e" || policyId === "933645b0-6306-41a2-a0e0-14a07e6e7023" || policyId === "3767c281-97f1-4fa5-ac66-41025ef4bb04" || policyId === "03a8e513-55be-4577-9635-08225b4aa343") && { name: "rejectionLetterLi", label: "Rejection Letter", fileName: "RejectionLetterLi" },
      (policyId === "9ac53f4c-c946-4034-9195-34cbaa97dd9e" || policyId === "933645b0-6306-41a2-a0e0-14a07e6e7023") && { name: "claimFormLi", label: "Claim Form", fileName: "ClaimFormLi" },
      { name: "communicationCompanyLi", label: "Communication Company", fileName: "CommunicationCompanyLi" },
      { name: "callRecordingLi", label: "Call Recording", fileName: "CallRecordingLi" },
      (policyId === "9ac53f4c-c946-4034-9195-34cbaa97dd9e" || policyId === "933645b0-6306-41a2-a0e0-14a07e6e7023") && { name: "dischargeSummLi", label: "Discharge Summary", fileName: "DischargeSummLi" },
      (policyId === "3767c281-97f1-4fa5-ac66-41025ef4bb04" || policyId == "03a8e513-55be-4577-9635-08225b4aa343") && { name: "drReportLi", label: "Doctor Report", fileName: "DrReportLi" },
      policyId !== "9ac53f4c-c946-4034-9195-34cbaa97dd9e" && policyId !== "933645b0-6306-41a2-a0e0-14a07e6e7023" && policyId !== "3767c281-97f1-4fa5-ac66-41025ef4bb04" && policyId !== "03a8e513-55be-4577-9635-08225b4aa343" && { name: "deathCertificate", label: "Death Certificate", fileName: "DeathCertificate" },
      (policyId === "3767c281-97f1-4fa5-ac66-41025ef4bb04" || policyId == "03a8e513-55be-4577-9635-08225b4aa343") && { name: "FIR", label: "FIR_", fileName: "FIR" },
      (policyId === "3767c281-97f1-4fa5-ac66-41025ef4bb04" || policyId == "03a8e513-55be-4577-9635-08225b4aa343") && { name: "postMortemReport", label: "PostMortem Report", fileName: "PostMortemReport" }
    ],

    "Health Insurance": [
      { name: "policyDocHi", label: "Policy Document", fileName: "PolicyDocHI_" },
      policyId !== "037af92a-b9e4-4454-a65f-ba6326faa6bf" && policyId !== "175adc16-a774-4749-83e4-1bac95fc098c" && { name: "dischargeSummary", label: "Discharge Summary", fileName: "DischargeSumm_" },
      (policyId == "e2f229b1-ba17-47cb-9731-9a0ecb8e13c1" || policyId == "7b0f97f9-ca77-4ef1-bdc9-fec05a7fbb56" || policyId == "037af92a-b9e4-4454-a65f-ba6326faa6bf" || policyId == "c4016e9f-9fd1-4018-b417-bec3a0f205d3" || policyId == "013eb20d-bfed-4b9e-8c81-5943f20dfcfb" || policyId == "175adc16-a774-4749-83e4-1bac95fc098c") && { name: "rejectionLetter", label: "Rejection Letter", fileName: "RejectionLetter" },
      policyId !== "037af92a-b9e4-4454-a65f-ba6326faa6bf" && policyId !== "175adc16-a774-4749-83e4-1bac95fc098c" && { name: "claimBill", label: "Claim Bill", fileName: "FinalBill" },
      (policyId == "a036aeec-a0b5-4114-ab0f-35fee6cce956" || policyId == "b3b2b137-1df5-4e3d-bb98-26466bde1dca" || policyId == "0e9aefb5-22e0-4d89-aa78-513ad9cc701f") && { name: "settlementLetterHi", label: "Settlement Letter", fileName: "SettlementLetterHi" },
      { name: "communicationCompanyHi", label: "Any communication", fileName: "CommunicationCompanyHi" },
      { name: "callRecordingHi", label: "Recording of the executive", fileName: "CallRecordingHi" },
      policyId !== "037af92a-b9e4-4454-a65f-ba6326faa6bf" && policyId !== "175adc16-a774-4749-83e4-1bac95fc098c" && policyId !== "013eb20d-bfed-4b9e-8c81-5943f20dfcfb" && policyId !== "c4016e9f-9fd1-4018-b417-bec3a0f205d3" && { name: "previousPolicyHi", label: "Previous Policy Copy", fileName: "PreviousPolicyHi" },
      policyId !== "c4016e9f-9fd1-4018-b417-bec3a0f205d3" && policyId !== "013eb20d-bfed-4b9e-8c81-5943f20dfcfb" && { name: "drCertificateHi", label: "Dr Certificate", fileName: "DrCertificateHi" },
      (policyId == "e2f229b1-ba17-47cb-9731-9a0ecb8e13c1" || policyId == "a036aeec-a0b5-4114-ab0f-35fee6cce956" || policyId == "275d7580-45c7-4c30-9f0e-099d13414082") && { name: "RT_PCRTestHi", label: "RT-PCR Test report", fileName: "RT_PCRTestHi" },
      (policyId == "037af92a-b9e4-4454-a65f-ba6326faa6bf" || policyId == "c4016e9f-9fd1-4018-b417-bec3a0f205d3" || policyId == "013eb20d-bfed-4b9e-8c81-5943f20dfcfb" || policyId == "175adc16-a774-4749-83e4-1bac95fc098c") && { name: "DeathCertificateHi", label: "Death Certificate", fileName: "DeathCertificateHi" },
      (policyId == "037af92a-b9e4-4454-a65f-ba6326faa6bf" || policyId == "175adc16-a774-4749-83e4-1bac95fc098c") && { name: "FIRHi", label: "FIR", fileName: "FIRHi_" },
      (policyId == "037af92a-b9e4-4454-a65f-ba6326faa6bf" || policyId == "175adc16-a774-4749-83e4-1bac95fc098c") && { name: "PostMortemReportHi", label: "PostMortem Report", fileName: "PostMortemReportHi" }
    ]
  };

  return docFields;
};

export const leadStatusConstant: { label: string; value: string }[] = [
  { label: "All", value: "All" },
  { label: "Pending", value: "PENDING" },
  { label: "Accepted", value: "ACCEPTED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Query", value: "QUERY" },
  { label: "Non Contactable", value: "NONCONTACTABLE" },
  { label: "Document Pending", value: "DOCUMENT_PENDING" },
  { label: "Document bifurcation Pending", value: "DOCUMENT BIFURCATION PENDING" },
  { label: "Auto Followup", value: "AUTOFOLLOWUP" },
  { label: "Repeated Leads", value: "REPEATED" },
  { label: "Expert Review", value: "EXPERT" },
  { label: "Expert Requirement Pending", value: "EXPERT REQUIREMENT PENDING" },
  { label: "Registered", value: "REGISTERED" },
  { label: "Customer Not Responding", value: "CUSTOMER_NOT_RESPONDING" },
  { label: "Doc. Verification Pending", value: "DOC VERIFICATION PENDING" },
  { label: "Doc. Upload Pending", value: "DOC UPLOAD PENDING" },
  { label: "Polifyx Pending", value: "POLIFYX PENDING" }
];

export const complaintStatusConstant: { label: string; value: string; subModules?: { label: string; value: string }[] }[] = [
  { label: "All", value: "All" },
  { label: "Accept", value: "Accept", subModules: [] },
  { label: "Pending", value: "Pending", subModules: [] },
  {
    label: "Mailing",
    value: "Mailing",
    subModules: [
      { label: "COMPLAINT FORM FILLED", value: "COMPLAINT FORM FILLED" },
      { label: "DRAFT MAIL GENERATED", value: "DRAFT MAIL GENERATED" },
      { label: "EXPERT APPROVED DRAFT MAIL", value: "EXPERT APPROVED DRAFT MAIL" },
      { label: "Company", value: "Company" },
      { label: "IGMS", value: "IGMS" },
      { label: "COMPANY/IGMS", value: "COMPANY/IGMS" },
      { label: "ESCALATION PENDING", value: "ESCALATION PENDING" },
      { label: "ESCALATION MAIL GENERATED", value: "ESCALATION MAIL GENERATED" },
      { label: "ESCALATION APPROVED", value: "ESCALATION APPROVED" },
      { label: "COMPANY ESCALATED", value: "COMPANY ESCALATED" },
      { label: "IGMS ESCALATED", value: "IGMS ESCALATED" },
      { label: "COMPANY/IGMS ESCALATED", value: "COMPANY/IGMS ESCALATED" },
      { label: "NO RESPONSE RECEIVED", value: "NO RESPONSE RECEIVED" },
      { label: "REMINDER MAIL SENT", value: "REMINDER MAIL SENT" },
      { label: "COMPANY REQUIREMENT RECEIVED", value: "COMPANY REQUIREMENT RECEIVED" },
      { label: "COMPANY REQUIREMENT SENT", value: "COMPANY REQUIREMENT SENT" }
    ]
  },
  {
    label: "Ombudsman",
    value: "Ombudsman",
    subModules: [
      { label: "Ombudsman Pending", value: "Ombudsman Pending" },
      { label: "COMPLAINT FORM SENT", value: "COMPLAINT FORM SENT" },
      { label: "Ombudsman without Legal", value: "Ombudsman without Legal" },
      { label: "OMBUDSMAN REQUIREMENT PENDING", value: "OMBUDSMAN REQUIREMENT PENDING" },
      { label: "OMBUDSMAN REQUIREMENT PUSHED", value: "OMBUDSMAN REQUIREMENT PUSHED" },
      { label: "OMBUDSMAN REQUIREMENT SENT", value: "OMBUDSMAN REQUIREMENT SENT" },
      { label: "FORM 6A RECEIVED", value: "FORM 6A RECEIVED" },
      { label: "FORM 6A PUSHED", value: "FORM 6A PUSHED" },
      { label: "FORM 6A SENT", value: "FORM 6A SENT" },
      { label: "HEARING DATE RECEIVED", value: "HEARING DATE RECEIVED" },
      { label: "HEARING POSTPONED", value: "HEARING POSTPONED" },
      { label: "HEARING DONE", value: "HEARING DONE" },
      { label: "AWARD ACCEPTED", value: "AWARD ACCEPTED" },
      { label: "AWARD REJECTED", value: "AWARD REJECTED" },
      { label: "Ombudsman with Legal", value: "Ombudsman with Legal" },
      { label: "OMBUDSMAN WITH LEGAL SENT", value: "OMBUDSMAN WITH LEGAL SENT" }
    ]
  },
  {
    label: "Legal",
    value: "Legal",
    subModules: [
      { label: "Legal", value: "Legal" },
      { label: "LEGAL CONTRACT PENDING", value: "LEGAL CONTRACT PENDING" },
      { label: "LEGAL CONTRACT SIGNED", value: "LEGAL CONTRACT SIGNED" },
      { label: "LEGAL QUERY RAISED", value: "LEGAL QUERY RAISED" },
      { label: "LEGAL QUERY ANSWERED", value: "LEGAL QUERY ANSWERED" },
      { label: "LEGAL DRAFT FOR APPROVAL", value: "LEGAL DRAFT FOR APPROVAL" },
      { label: "COURT FILING POINTS", value: "COURT FILING POINTS" },
      { label: "COURT FILING PENDING BUCKET", value: "COURT FILING PENDING BUCKET" },
      { label: "CASE FILING DONE", value: "CASE FILING DONE" },
      { label: "LEGAL RECOVERY FROM CUSTOMER", value: "LEGAL RECOVERY FROM CUSTOMER" },
      { label: "LEGAL NOTICE RECEIVED", value: "LEGAL NOTICE RECEIVED" },
      { label: "LEGAL NOTICE SENT TO CUSTOMER", value: "LEGAL NOTICE SENT TO CUSTOMER" },
      { label: "LEGAL NOTICE SENT TO COMPANY", value: "LEGAL NOTICE SENT TO COMPANY" },
      { label: "LEGAL RECOVERY FROM COMPANY", value: "LEGAL RECOVERY FROM COMPANY" },
      { label: "COURT ISSUED SUMMON", value: "COURT ISSUED SUMMON" },
      { label: "COMPANY REPLY AWAITED", value: "COMPANY REPLY AWAITED" },
      { label: "REJOINDER NEED TO BE FILED", value: "REJOINDER NEED TO BE FILED" },
      { label: "AFFIDAVIT NEED TO BE FILED", value: "AFFIDAVIT NEED TO BE FILED" },
      { label: "COMPLAINANT/PLAINTIFF EVIDENCE", value: "COMPLAINANT/PLAINTIFF EVIDENCE" },
      { label: "DEFENDANT/RESPONDENT EVIDENCE", value: "DEFENDANT/RESPONDENT EVIDENCE" },
      { label: "SETTLEMENT PROPOSAL SUBMITTION PENDING", value: "SETTLEMENT PROPOSAL SUBMITTION PENDING" },
      { label: "SETTLEMENT PROPOSAL SUBMITTED", value: "SETTLEMENT PROPOSAL SUBMITTED" },
      { label: "ARGUMENTS", value: "ARGUMENTS" },
      { label: "FINAL ARGUMENTS", value: "FINAL ARGUMENTS" },
      { label: "ORDER AWAITED", value: "ORDER AWAITED" }
    ]
  },
  {
    label: "Account",
    value: "Account",
    subModules: [
      // { label: "Resolved", value: "Resolved" },
      { label: "INVOICE RAISED", value: "INVOICE RAISED" },
      { label: "SETTLED", value: "Settled" },
      { label: "INVOICE PROCESSING", value: "INVOICE PROCESSING" }
    ]
  },
  { label: "Resolved", value: "Resolved", subModules: [] },
  { label: "Reimbursement", value: "Reimbursement", subModules: [] },
  {
    label: "Rejected",
    value: "Rejected",
    subModules: [
      { label: "INSA REJECTED", value: "INSA REJECTED" },
      { label: "Unresolved", value: "UnResolved" },
      { label: "Rejected", value: "Rejected" },
      { label: "CUSTOMER WITHDRAW", value: "CUSTOMER WITHDRAW" },
      { label: "WRITTEN OFF", value: "WRITTEN OFF" },
      { label: "NON RESPONSIVE CUSTOMER", value: "NON RESPONSIVE CUSTOMER" }
    ]
  },
  { label: "Company Payment Pending", value: "Company Payment Pending", subModules: [] },
  { label: "Customer Payment Pending", value: "Customer Payment Pending", subModules: [] }
];

export const policyDocs: any = [
  {
    name: "Policy Document",
    value: "PolicyDocHI"
  },
  {
    name: "Discharge Summary",
    value: "DischargeSumm"
  },
  {
    name: "Rejection Letter",
    value: "RejectionLetter"
  },
  {
    name: "Final Bill",
    value: "FinalBill"
  },
  {
    name: "Settlement Letter",
    value: "SettlementLetterHi"
  },
  {
    name: "Any Communication",
    value: "CommunicationCompanyHi"
  },
  {
    name: "Recording of the executive",
    value: "CallRecordingHi"
  },
  {
    name: "Previous Policy Copy",
    value: "PreviousPolicyHi"
  },
  {
    name: "Dr Certificate",
    value: "DrCertificateHi"
  },
  {
    name: "RT-PCR Test report",
    value: "RT_PCRTestHi"
  },
  {
    name: "Death Certificate",
    value: "DeathCertificateHi"
  },
  {
    name: "FIR",
    value: "FIRHi"
  },
  {
    name: "Post Mortem Report",
    value: "PostMortemReportHi"
  },
  {
    name: "Other doc",
    value: "OtherDocs"
  }
];

interface bucketsConstantsType {
  index: number;
  name: string;
  subBucket: string[];
  selectedSubBucket: string;
}

export const bucketsConstants: bucketsConstantsType[] = [
  {
    index: 0,
    name: "Registration",
    subBucket: ["Partner Registration Dashboard"],
    selectedSubBucket: "Partner Registration Dashboard"
  },
  {
    index: 1,
    name: "Mailing",
    subBucket: ["Partner Mailing Dashboard"],
    selectedSubBucket: "Partner Mailing Dashboard"
  },
  {
    index: 2,
    name: "Ombudsman",
    subBucket: ["Partner Ombudsman Dashboard", "Partner Resend Cases"],
    selectedSubBucket: "Partner Ombudsman Dashboard"
  },
  {
    index: 3,
    name: "Legal",
    subBucket: ["Partner Dashboard"],
    selectedSubBucket: "Partner Dashboard"
  },
  {
    index: 4,
    name: "Resolution",
    subBucket: ["Partner Resolution Dashboard", "Partner Settled Cases", "Partner Invoice Cases"],
    selectedSubBucket: "Partner Resolution Dashboard"
  }
];

export const legalDashboardConstants: { name: string; amount: boolean; key: string }[] = [
  {
    name: "Cases for which invoicing is done",
    amount: false,
    key: "complaintCaseInvoice"
  },
  {
    name: "Cases Registered in GI",
    amount: false,
    key: "complaintCaseGI"
  },
  {
    name: "Total claim amount in GI",
    amount: true,
    key: "claimComplaintCaseGI"
  },
  {
    name: "No. of Cases Registered in HI",
    amount: false,
    key: "complaintCaseHI"
  },
  {
    name: "Total claim amount in HI",
    amount: true,
    key: "claimComplaintCaseHI"
  },
  {
    name: "No. of Cases Registered in LI",
    amount: false,
    key: "complaintCaseLI"
  },
  {
    name: "Total claim amount in LI",
    amount: true,
    key: "claimComplaintCaseLI"
  },
  {
    name: "No. of Cases in IGMS",
    amount: false,
    key: "complaintCaseIGMS"
  },
  {
    name: "Total claim amount in IGMS",
    amount: true,
    key: "claimComplaintCaseIGMS"
  },
  {
    name: "No. of Cases in Ombudsman",
    amount: false,
    key: "complaintCaseOMD"
  },

  {
    name: "Total claim amount in Ombudsman",
    amount: true,
    key: "claimComplaintCaseOMD"
  },
  {
    name: "As a Service Cases",
    amount: false,
    key: "caseAsAService"
  },
  {
    name: "Total claim amount in As a Service Cases",
    amount: true,
    key: "claimCaseAsAService"
  },
  {
    name: "Total Number of cases(01/Jan/2021 - 31/12/2021)",
    amount: false,
    key: "complaintCaseYear"
  },
  {
    name: "Total claim amount(01/Jan/2021 - 31/12/2021)",
    amount: true,
    key: "claimComplaintCaseYear"
  }
];

export const DashboardInsuranceConstants = [
  {
    name: "Life Insurance",
    icon: LifeIcon,
    bg: "bg-[#E3F9F4]",
    section: {
      "Partner Registration Dashboard": [
        {
          title: "Cases : ",
          key: "caseMonthLi",
          amount: false
        },
        {
          title: "Customers : ",
          key: "allCustomerMonthLi",
          amount: false
        },
        {
          title: "Claim Amount",
          key: "claimCaseLi",
          amount: true
        }
      ],
      "Partner Resolution Dashboard": [
        {
          title: "Cases : ",
          key: "caseMonthLi",
          amount: false
        },
        {
          title: "Customers : ",
          key: "allCustomerMonthLi",
          amount: false
        },
        {
          title: "Claim Amount",
          key: "claimCaseLi",
          amount: true
        },
        {
          title: "Approved Claim Amount",
          key: "claimCaseTotalLi",
          amount: true
        },
        {
          title: "Pending Claim Amount",
          key: "claimCasePendingLi",
          amount: true
        }
      ],
      "Partner Settled Cases": [
        {
          title: "Cases :",
          key: "lifeCount"
        },
        {
          title: "Revenue :",
          key: "lifeAmt"
        }
      ],
      "Partner Invoice Cases": [
        {
          title: "Cases :",
          key: "lifeCount"
        },
        {
          title: "Final Amt to be paid :",
          key: "lifeAmt",
          amount: true
        }
      ]
    }
  },
  {
    name: "Health Insurance",
    icon: HealthIcon,
    bg: "bg-[#E9EEFA]",
    section: {
      "Partner Registration Dashboard": [
        {
          title: "Cases : ",
          key: "caseMonthHi",
          amount: false
        },
        {
          title: "Customers : ",
          key: "allCustomerMonthHi",
          amount: false
        },
        {
          title: "Claim Amount",
          key: "claimCaseHi",
          amount: true
        }
      ],
      "Partner Resolution Dashboard": [
        {
          title: "Cases : ",
          key: "caseMonthHi",
          amount: false
        },
        {
          title: "Customers : ",
          key: "allCustomerMonthHi",
          amount: false
        },
        {
          title: "Claim Amount",
          key: "claimCaseHi",
          amount: true
        },
        {
          title: "Approved Claim Amount",
          key: "claimCaseTotalHi",
          amount: true
        },
        {
          title: "Pending Claim Amount",
          key: "claimCasePendingHi",
          amount: true
        }
      ],
      "Partner Settled Cases": [
        {
          title: "Cases :",
          key: "healthCount"
        },
        {
          title: "Revenue :",
          key: "healthAmt"
        }
      ],
      "Partner Invoice Cases": [
        {
          title: "Cases :",
          key: "healthCount"
        },
        {
          title: "Final Amt to be paid :",
          key: "healthAmt",
          amount: true
        }
      ]
    }
  },
  {
    name: "General Insurance",
    icon: GeneralIcon,
    bg: "bg-[#ECE5FA]",

    section: {
      "Partner Registration Dashboard": [
        {
          title: "Cases : ",
          key: "caseMonthGi",
          amount: false
        },
        {
          title: "Customers : ",
          key: "allCustomerMonthGi",
          amount: false
        },
        {
          title: "Claim Amount",
          key: "claimCaseGi",
          amount: true
        }
      ],
      "Partner Resolution Dashboard": [
        {
          title: "Cases : ",
          key: "caseMonthGi",
          amount: false
        },
        {
          title: "Customers : ",
          key: "allCustomerMonthGi",
          amount: false
        },
        {
          title: "Claim Amount",
          key: "claimCaseGi",
          amount: true
        },
        {
          title: "Approved Claim Amount",
          key: "claimCaseTotalGi",
          amount: true
        },
        {
          title: "Pending Claim Amount",
          key: "claimCasePendingGi",
          amount: true
        }
      ],
      "Partner Settled Cases": [
        {
          title: "Cases :",
          key: "generalCount"
        },
        {
          title: "Revenue :",
          key: "generalAmt"
        }
      ],
      "Partner Invoice Cases": [
        {
          title: "Cases :",
          key: "generalCount"
        },
        {
          title: "Final Amt to be paid :",
          key: "generalAmt",
          amount: true
        }
      ]
    }
  },
  {
    name: "Total",
    bg: "bg-[#DCF4FC]",

    section: {
      "Partner Registration Dashboard": [
        {
          title: "Cases : ",
          key: "caseMonth",
          amount: false
        },
        {
          title: "Customers : ",
          key: "allCustomerMonth",
          amount: false
        },
        {
          title: "Claim Amount",
          key: "claimCase",
          amount: true
        }
      ],
      "Partner Resolution Dashboard": [
        {
          title: "Cases : ",
          key: "caseMonth",
          amount: false
        },
        {
          title: "Customers : ",
          key: "allCustomerMonth",
          amount: false
        },
        {
          title: "Claim Amount",
          key: "claimCase",
          amount: true
        },
        {
          title: "Approved Claim Amount",
          key: "claimCaseTotal",
          amount: true
        },
        {
          title: "Pending Claim Amount",
          key: "claimCasePending",
          amount: true
        }
      ],
      "Partner Settled Cases": [
        {
          title: "Cases :",
          key: "sendDataCount"
        },
        {
          title: "Revenue :",
          key: "totalAmt"
        }
      ],
      "Partner Invoice Cases": [
        {
          title: "Cases :",
          key: "sendDataCount"
        },
        {
          title: "Final Amt to be paid :",
          key: "totalAmt",
          amount: true
        }
      ]
    }
  }
];

export const nameOfStates: string[] = ["Andaman & Nicobar", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman & Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu & Kashmir", "Jharkhand", "Karnataka", "Kerala", "Lakshadweep", "Ladakh", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh"];

export const ombudsmanStatus: string[] = ["Ombudsman Pending", "OMBUDSMAN REQUIREMENT PENDING", "OMBUDSMAN REQUIREMENT PUSHED", "COMPLAINT FORM SENT", "OMBUDSMAN REQUIREMENT SENT", "Ombudsman without Legal", "FORM 6A RECEIVED", "FORM 6A PUSHED", "FORM 6A SENT", "HEARING DATE RECEIVED", "HEARING POSTPONED", "HEARING DONE", "AWARD ACCEPTED", "AWARD REJECTED"];

export const PERMISSIONS = {
  ADMIN: "ADMIN",
  PARTNER: "PARTNER",
  ASSOCIATE: "ASSOCIATE",
  ADVISOR: "ADVISOR"
};

export const generalCaseType: any = [
  { value: "Travel", label: "Travel" },
  { value: "Fidelity", label: "Fidelity" },
  { value: "Marine", label: "Marine" },
  { value: "Property", label: "Property" },
  { value: "Fire", label: "Fire" },
  { value: "Home Insurance", label: "Home Insurance" },
  { value: "Personal Accident", label: "Personal Accident" },
  { value: "Critical Illness", label: "Critical Illness" },
  { value: "PMSBY Claim", label: "PMSBY Claim" },
  { value: "Loan Protection Policy", label: "Loan Protection Policy" },
  { value: "Death Claim", label: "Death Claim" },
  { value: "Others", label: "Others" }
];

export const thirdPartyUsers: string[] = ["c9314c8c-b34e-4e0a-b059-d15b616d7cde", "19d3804e-9e16-4d80-ab28-3ad9c2f6faa4"];
